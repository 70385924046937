import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Sidebar from './Sidebar';
import './HomePage.css';
import PaymentPage from './PaymentPage';
import ChatbotConfigPage from './ChatbotConfigPage';
import DeployChatbots from './ChatbotDeploymentPage';
import ChatbotsPage from './ChatbotsPage';
import CreateChatbot from './CreateChatbot'; // Import the new componentc
import TrainChatbots from './TrainChatbots'; // Import TrainChatbots
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet";

function HomePage() {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState('profile');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [imageTimestamp, setImageTimestamp] = useState(Date.now());
  const [uploadProgress, setUploadProgress] = useState(0);
  const [trainingProgress, setTrainingProgress] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [fileError, setFileError] = useState('');
  const [imageError, setImageError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [chatbots, setChatbots] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updatedUser, setUpdatedUser] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);

  const navigate = useNavigate();
  const menuRef = useRef(null);
  const fileInputRef = useRef(null);
  const profileImageInputRef = useRef(null);

  const user = JSON.parse(localStorage.getItem('user'));
  const username = user ? user.username : null;
  const user_id = user ? user.user_id : null;
  // const password = user ? user.password : null;

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    setSelectedUser(null);
    navigate('/');
  };

  const axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const getUserProfile = async (username) => {
    try {
      const response = await axiosInstance.post('https://dryangai.com/api/getUserProfile/', {
        username,
      });
      setSelectedUser(response.data.user);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user:', error);
      setError('Error fetching user data');
      setLoading(false);
    }
  };

  const getUserChatbots = async (user_id) => {
    try {
      const response = await axiosInstance.post('https://dryangai.com/api/getUserChatbots/', {
        user_id,
      });
      setChatbots(response.data.chatbots);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user chatbots:', error);
      setError('Error fetching user chatbots');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activePage === 'profile' && username) {
      getUserProfile(username);
    }
    if (activePage === 'chatbots' && user_id) {
      getUserChatbots(user_id);
    }
    if (activePage === 'trainChatbots' && user_id) {
      getUserChatbots(user_id);
    }
    if (activePage === 'deployChatbots' && user_id) {
      getUserChatbots(user_id);
    }
    if (activePage === 'configureChatbots' && user_id) {
      getUserChatbots(user_id);
    }
  }, [activePage, username]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const handleMenuClick = (page) => {
    setActivePage(page);
    setLoading(true);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'svg'];
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (!validExtensions.includes(fileExtension)) {
      setImageError('Only image formats (JPG, JPEG, PNG, GIF) are allowed.');
      return;
    }

    const formData = new FormData();
    formData.append('profile_image', file);
    formData.append('user_id', user_id);

    try {
      const response = await axios.post('https://dryangai.com/api/uploadProfileImage/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const newProfileImageUrl = response.data.profile_image_url;

      setSelectedUser(prevUser => ({
        ...prevUser,
        profile_image_url: newProfileImageUrl,
      }));

      const updatedUser = { ...user, profile_image_url: newProfileImageUrl };
      localStorage.setItem('user', JSON.stringify(updatedUser));

      setImageTimestamp(response.data.unique_id);
      setImageError('');

    } catch (error) {
      console.error('Error uploading profile image:', error);
      setModalMessage('Error uploading profile image');
      setShowModal(true);
    }
  };

  const handleImageClick = () => {
    profileImageInputRef.current.click();
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const addToCreditBalance = () => {
    setShowPaymentModal(true);
  };

  const handlePaymentSuccess = () => {
    setShowPaymentModal(false);
    getUserProfile(username);
  };

  const handleEditClick = () => {
    setEditMode(true);
    setUpdatedUser({
      password: selectedUser.password,
      email: selectedUser.email,
      phone: selectedUser.phone,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser({ ...updatedUser, [name]: value });
  };

  const handleSaveClick = async () => {
    try {
      const response = await axiosInstance.post('https://dryangai.com/api/updateUserProfile/', {
        user_id,
        ...updatedUser,
      });
      
      if (response.data.success) {
        setSelectedUser({ ...selectedUser, ...updatedUser });
        setEditMode(false);
        setModalMessage('Profile updated successfully');
        setShowModal(true);
      } else {
        throw new Error(response.data.message || 'Failed to update profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setModalMessage('Error updating profile: ' + error.message);
      setShowModal(true);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="container">
      <Helmet>
      <title>Customize your own chatbot -- Dr. Yang AI</title>
      <meta name="description" content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot.." />
      <meta name="keywords" content="AI, Chatbot, Customized, Personal, Customer Service, Knowledge" />
      </Helmet>
      {user ? (
        <>
          <div className='welcomeUser'>
            <p style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              {user && (
                <img
                  className="profile-image-header"
                  src={`https://dryangai.com/api/media/${user.profile_image_url}?t=${imageTimestamp}`}
                  alt="Profile"
                  onClick={toggleMenu}
                  onError={(e) => e.target.src = 'https://dryangai.com/api/media/users/defaultProfileImage.png'}
                />
              )}
            </p>
            {menuVisible && (
              <div className='menu' ref={menuRef}>
                <div className='menu-profile'>
                  <img
                    className="profile-image-header"
                    src={`https://dryangai.com/api/media/${user.profile_image_url}?t=${imageTimestamp}`}
                    alt="Profile"
                    onError={(e) => e.target.src = 'https://dryangai.com/api/media/users/defaultProfileImage.png'}
                  />
                  <div className='menu-username'>
                    <span style={{ display: 'block' }}>
                      {user.username}&nbsp;&nbsp;
                    </span>
                    <span style={{ display: 'block' }}>
                      {user.email}&nbsp;&nbsp;
                    </span>
                  </div>
                </div>
                <div className='menu-divider'><a href='#' onClick={() => handleMenuClick('profile')}>&nbsp;&nbsp;{t('Profile')}</a></div>
                <div className='menu-divider'><a href='#' onClick={() => handleMenuClick('createChatbot')}>&nbsp;&nbsp;{t('Create Chatbot')}</a></div>
                <div className='menu-divider'><a href='#' onClick={() => handleMenuClick('chatbots')}>&nbsp;&nbsp;{t('chatbotList')}</a></div>
                <div className='menu-divider'><a href='#' onClick={() => handleMenuClick('trainChatbots')}>&nbsp;&nbsp;{t('Customize Chatbot')}</a></div>
                <div className='menu-divider'><a href='#' onClick={() => handleMenuClick('configureChatbots')}>&nbsp;&nbsp;{t('Config Chatbot')}</a></div>
                <div className='menu-divider'><a href='#' onClick={() => handleMenuClick('deployChatbots')}>&nbsp;&nbsp;{t('Deploy Chatbot')}</a></div>
                <div className='menu-divider'><a href='#' onClick={handleLogout}>&nbsp;&nbsp;{t('Logout')}</a></div>
              </div>
            )}
          </div>
          <Sidebar
            onMenuClick={handleMenuClick}
            toggleSidebar={toggleSidebar}
            isSidebarOpen={isSidebarOpen}
          />
          <div className={`main-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
            {activePage === 'home' && (
              <div className="home-content">
                <h2>Welcome to the Home Page</h2>
                <p>This is the home page content.</p>
              </div>
            )}

            {activePage === 'profile' && selectedUser && (
              <div className="profile-details">
                <h2>{t('User Profile')}</h2>
                <p>
                  <img
                    className="profile-image"
                    src={`https://dryangai.com/api/media/${selectedUser.profile_image_url}?t=${imageTimestamp}`}
                    alt="Profile"
                    onClick={handleImageClick}
                    style={{ cursor: 'pointer' }}
                    onError={(e) => e.target.src = 'https://dryangai.com/api/media/users/defaultProfileImage.png'}
                  />
                </p>
                <input
                  type="file"
                  ref={profileImageInputRef}
                  style={{ display: 'none' }}
                  onChange={handleImageUpload}
                />
                {imageError && <p className="error-message">{imageError}</p>}
                <p><strong>ID:</strong> {selectedUser.user_id}</p>
                <p><strong>{t('username')}:</strong> {selectedUser.username}</p>
                
                {editMode ? (
                  <Form>
                    <Form.Group>
                      <Form.Label><strong>{t('password')}:</strong></Form.Label>
                      <div className="password-container">
                        <Form.Control
                          type={passwordVisible ? 'text' : 'password'}
                          name="password"
                          value={updatedUser.password}
                          onChange={handleInputChange}
                        />
                        <FontAwesomeIcon
                          icon={passwordVisible ? faEyeSlash : faEye}
                          className="password-icon"
                          onClick={togglePasswordVisibility}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label><strong>{t('email')}:</strong></Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={updatedUser.email}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label><strong>{t('phone')}:</strong></Form.Label>
                      <Form.Control
                        type="tel"
                        name="phone"
                        value={updatedUser.phone}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Button variant="primary" onClick={handleSaveClick}>
                      {t('Save Changes')}
                    </Button>
                    <Button variant="secondary" onClick={() => setEditMode(false)}>
                      {t('Cancel')}
                    </Button>
                  </Form>
                ) : (
                  <>
                    <p><strong>{t('password')}:</strong> ********</p>
                    <p><strong>{t('email')}:</strong> {selectedUser.email}</p>
                    <p><strong>{t('phone')}:</strong> {selectedUser.phone}</p>
                    <Button variant="primary" onClick={handleEditClick}>
                      {t('Edit Profile')}
                    </Button>
                  </>
                )}
                
                <p><strong>{t('creditBalance')}:</strong> ${selectedUser.balance} <button className="btn btn-primary" onClick={addToCreditBalance}>{t('addCredit')}</button></p>
              </div>
            )}

            {activePage === 'profile' && !selectedUser && error && (
              <p>{error}</p>
            )}

            {activePage === 'createChatbot' && (
              <CreateChatbot
                setActivePage={setActivePage}
                user_id={user_id}
                axiosInstance={axiosInstance}
                setModalMessage={setModalMessage}
                setShowModal={setShowModal}
              />
            )}

            {activePage === 'chatbots' && (
              <ChatbotsPage user_id={user_id} />
            )}

            {activePage === 'configureChatbots' && (
              <ChatbotConfigPage 
                chatbots={chatbots} // Pass necessary props
                user_id={user_id}
              />
            )}
            {activePage === 'deployChatbots' && (
              <DeployChatbots 
              chatbots={chatbots} // Pass necessary props
              user_id={user_id}/>
            )}

            {activePage === 'trainChatbots' && (
              <TrainChatbots
                chatbots={chatbots} // Pass necessary props
                user_id={user_id}
                // selectedChatbotName={selectedChatbotName}
                // setSelectedChatbotName={setSelectedChatbotName}
              />
            )}
          </div>
        </>
      ) : (
      <div className='register'>
        <p className='links'>
          <a href="register" onClick={() => handleMenuClick('register')}>{t('register')}</a>
          <a href="login" onClick={() => handleMenuClick('login')}>{t('login')}</a>
        </p>
      </div>
      )}

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Add to Credit Balance')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentPage user_id={user_id} onSuccess={handlePaymentSuccess} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPaymentModal(false)}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default HomePage;
