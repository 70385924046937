import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

const DeployChatbots = ({ chatbots }) => {
  const { t } = useTranslation();
  const [selectedChatbotName, setSelectedChatbotName] = useState('');
  const [selectedChatbotID, setSelectedChatbotID] = useState('');
  const [selectedChatbotStyle, setSelectedChatbotStyle] = useState('');
  const [buttonText, setButtonText] = useState(t('Copy'));

  const handleChatbotChange = (event) => {
    const selectedChatbotName = event.target.value;
    setSelectedChatbotName(selectedChatbotName);
    const selectedChatbot = chatbots.find(chatbot => chatbot.chatbot_name === selectedChatbotName);
    setSelectedChatbotID(selectedChatbot ? selectedChatbot.chatbot_id : '');
  };

  const handleStyleChange = (event) => {
    setSelectedChatbotStyle(event.target.value);
  };

  const chatbotBubbleSnippet = `
  <script src="https://dryangai.com/api/media/chatbots/examples/chatbot_Bubble.js" chatbotID="${selectedChatbotID}" defer></script>`;
  const chatbotBoxSnippet = `
  <div id="chatbot-wrapper" style="position:relative; left:0px; top:5px; width:100%; height:500px;z-index:4;">
  <script src="https://dryangai.com/api/media/chatbots/examples/chatbot_Box.js" chatbotID="${selectedChatbotID}" defer></script>
  </div>
`;

  const customSnipetStyle = {
    lineHeight: '1.5',
    fontSize: '1rem',
    borderRadius: '5px',
    backgroundColor: '#f7f7f7',
    padding: '20px',
    border: '1px solid #e1e1e1',
    width: '100%',
    position: 'relative',
  };

  const handleCopy = (snippet) => {
    if (!selectedChatbotName) {
      alert('Please choose a chatbot before copying the snippet');
      return;
    }
    navigator.clipboard.writeText(snippet).then(() => {
      setButtonText(t('Copied'));
      setTimeout(() => setButtonText(t('Copy')), 2000); // Change back to 'Copy' after 2 seconds
    });
  };

  return (
    <div>
      <Helmet>
      <title>Deploy my chatbots -- Dr. Yang AI</title>
      <meta name="description" content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot.." />
      <meta name="keywords" content="AI, Chatbot, Customized, Personal, Customer Service, Knowledge" />
      </Helmet>


      <h2>{t('deployChatbot')}</h2>
      <h4>{t('step1')}: {t('chooseChatbot')} </h4>
      {t('selectAChatbotToDeploy')}:
      <select name="selectedChatbotName" className="form-control" onChange={handleChatbotChange}>
        <option value="">{t('selectAChatbot')}</option>
        {chatbots.map(chatbot => (
          <option key={chatbot.chatbot_id} value={chatbot.chatbot_name}>
            {chatbot.chatbot_name}
          </option>
        ))}
      </select>
      {!selectedChatbotName && <span style={{ color: 'red' }}>{t('pleaseSelectAChatbot')}</span>}
      {selectedChatbotName && <span style={{ color: 'green' }}>{t('selected')} {selectedChatbotName}</span>}
      <br />
      {selectedChatbotName && 
      <div>
        <h4>{t('step2')}: {t('chooseChatbotStyle')}</h4>
        {t('Select the style of the chatbot to be installed on your platform: popup or embbeded')}
        <select name="chatbotStyle" className="form-control" onChange={handleStyleChange}>
          <option value="">{t('select a style')}</option>
          <option value="Popup">{t('Popup Chatbot')}</option>
          <option value="Embedded">{t('Embedded Chatbot')}</option>
        </select>
          <option value="chat-bubble">{t('Popup Chatbot')}</option>
        {!selectedChatbotStyle && <span style={{ color: 'red' }}>{t('please select a style')}</span>}
        {selectedChatbotStyle && <span style={{ color: 'green' }}>{t('selected')}{t(selectedChatbotStyle)} </span>}
        <br />
        <h4>{t('step3')}: {t('Copy Code Snippet')}</h4>
        <div className='snippetContainer' style={{ position: 'relative' }}>
          <button
            className='copySnippetButton'
            onClick={() => handleCopy(selectedChatbotStyle === 'Popup' ? chatbotBubbleSnippet : chatbotBoxSnippet)}
            disabled={!selectedChatbotStyle}
          >
            {buttonText}
          </button>
          {selectedChatbotStyle === 'Popup' && (
            <div>
            <SyntaxHighlighter language="html" style={coy} customStyle={customSnipetStyle} wrapLongLines={true}>
              {chatbotBubbleSnippet}
            </SyntaxHighlighter>
              <a href={`https://dryangai.com/api/media/chatbots/examples/chatbot_Bubble.html?chatbotID=${selectedChatbotID}`} target='_blank'>{t('See example')}</a>
              </div>
          )}
          {selectedChatbotStyle === 'Embedded' && (
            <div>
            <SyntaxHighlighter language="html" style={coy} customStyle={customSnipetStyle} wrapLongLines={true}>
              {chatbotBoxSnippet}
            </SyntaxHighlighter>
            <a href={`https://dryangai.com/api/media/chatbots/examples/chatbot_Box.html?chatbotID=${selectedChatbotID}`} target='_blank'>{t('See example')}</a>
            </div>
          )}
        </div>
      </div>}
    </div>
  );
};

export default DeployChatbots;
