import React from 'react';
import './IndexPage.css';
import { useTranslation } from 'react-i18next';

function Index() {
  const { t } = useTranslation();

  // Function to check if the user is logged in
  const isLoggedIn = () => {
    return localStorage.getItem('user') !== null;
  };

  // Handle Manage Your Chatbot button click
  const handleManageChatbot = () => {
    if (isLoggedIn()) {
      window.location.href = "https://dryangai.com/home";
    } else {
      window.location.href = "https://dryangai.com/login";
    }
  };

  return (
    
    <div className="app">
      {/* Navigation Bar */}
      <header className="navbar">
        <nav>
          <ul>
            <li><a href="#features">{t('Features')}</a></li>
            <li><a href="#pricing">{t('Pricing')}</a></li>
            <li><a href="#about">{t('About')}</a></li>
            <li><a href="#video">{t('Video Demo')}</a></li>
            <li><a href="#contact">{t('Contact')}</a></li>
          </ul>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <h1 className="gradient-text">{t('Welcome to Dr. Yang AI')} ✨</h1>
        <h1>{t('Customized chatbot services tailored to your needs')}</h1>
        <div className="cta-buttons">
        <button 
            className="outline-btn" 
            onClick={() => window.open("https://dryangai.com/api/media/chatbots/examples/chatbot_Box.html?chatbotID=98", "_blank")}>
            {t('Try a Demo')}
        </button>
          <button 
            className="filled-btn" 
            onClick={() => window.location.href="https://dryangai.com/register"}>
            {t('Sign up for Free')} <span className="arrow">→</span>
          </button>
          <button 
            className="cta-button" 
            onClick={handleManageChatbot}>
            {t('Manage Your Chatbots')}
          </button>
        </div>

        <br />
        <br />
        <p className="cta-text">
        {t('Dr. Yang AI empowers you to design, build, and launch custom chatbots tailored to your brand, making it easier to manage your marketing across all social platforms—perfect for businesses of any size, from startups to large enterprises.')}</p>
      </section>

      {/* Features Section */}
      <section id="features" className="features">
        <h2>{t('Features')}</h2>
        <div className="feature-list">
          <div className="feature-item">
            <h3>{t('Customized Chatbots')}</h3>
            <p>{t('Create chatbots tailored to your specific knowledge by uploading documents and resources.')}</p>
          </div>
          <div className="feature-item">
            <h3>{t('AI-Powered Conversations')}</h3>
            <p>{t('Leverage advanced AI models to provide intelligent responses and seamless interactions with users.')}</p>
          </div>
          <div className="feature-item">
            <h3>{t('Easy Integration')}</h3>
            <p>{t('Integrate your chatbot into websites, apps, or other platforms effortlessly with our API.')}</p>
          </div>
        </div>
      </section>

      {/* Video Section */}
      <section  className="video-section">
        <h2>{t('Video Demo')}</h2>             
        <div className='video-list' id="video">
        <div className='video-item' >
            <video 
                src="https://dryangai.com/api/media/videos/chatbotDemo.mp4" 
                autoPlay 
                loop 
                muted 
                playsInline
                width="100%" // Adjust the width as needed
                style={{ borderRadius: '15px' }} // Fixed value for uniform round corners
            />
        </div>
        </div>
     </section>

    



      {/* Pricing Section */}
      <section id="pricing" className="pricing">
        <h2>{t('Pricing')}</h2>
        <div className="pricing-table">

    {/* Free Plan */}
    <div className="pricing-option">
      <h3>{t('Free')}</h3>
      <p>{t('Free Trial')}</p>
      <p><span className='priceValue'>$0</span> </p>
      <p>{t('Unleash your creativity.')}</p>
      <button>{t('Get Started')}</button>
      <ul>
        <li>1 {t('Chatbot')}</li>
        <li>10k AI {t('Tokens')}</li>
        <li>{t('Custom chatbot name')}</li>
        <li>1 {t('Custom Example Questions')}</li>
        <li>{t('Deploy to')} 1 {t('websites')}</li>
      </ul>
    </div>

    {/* Simplified One Plan */}
    <div className="pricing-option">
      <h3>{t('Pro')}</h3>
      <p>{t('Best for personal use.')}</p>
      <p><span className='priceValue'>$29.99/{t('month')}</span></p>
      <p>{t('More chatbots & customization.')}</p>
      <button>{t('Get Started')}</button>
      <ul>
        <li>10 {t('Chatbots')}</li>
        <li>20M AI {t('Tokens')}</li>
        <li>{t('Custom Name, Logo, Greeting')} </li>
        <li>8 {t('Custom Example Questions')} </li>
        <li>{t('Deploy to')} 10 {t('Websites')}</li>
      </ul>
    </div>

    {/* Simplified Enterprise Plan */}
    <div className="pricing-option">
      <h3>{t('Enterprise')}</h3>
      <p>{t('Best for teams and agencies.')}</p>
      <p><span className='priceValue'>$99.99/{t('month')}</span></p>
      <p> {t('Tailored plan to meet your needs.')}</p>
      <button>{t('Get Started')}</button>
      <ul>
        <li>{t('Unlimited Chatbots')}</li>
        <li>{t('Unlimited AI Tokens')}</li>
        <li>{t('Deploy to Unlimited Websites')}</li>
        <li>API {t('Access')}</li>
        <li>{t('Full Customization')}</li>
        <li>{t('Workflow Automations')}</li>
        <li>{t('Priority Support')}</li>
      </ul>
    </div>

  </div>
</section>



      {/* About Section */}
      <section id="about" className="about">
        <h2>{t('About Dr. Yang AI')}</h2>
        <p>
          {t('Dr. Yang AI is a cutting-edge platform that enables users to create highly customizable chatbots. By simply providing your own documents or resources, our AI can generate chatbots with knowledge tailored to your specific needs, making customer interactions seamless and personalized.')}
        </p>
      </section>

      {/* Footer */}
      <footer id="contact" className="footer">
        <div className="footer-info">
          <p>{t('Contact us')}: info@dryangai.com</p>
          <p>&copy; 2024 Dr. Yang AI. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default Index;
