import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

function ChatbotConfigPage({ chatbots }) {
  const { t } = useTranslation();
  const [selectedChatbotName, setSelectedChatbotName] = useState('');
  const [selectedChatbotID, setSelectedChatbotID] = useState('');
  const [domain, setDomain] = useState('');
  const [allowedDomains, setAllowedDomains] = useState([]);
  const [exampleQuestions, setExampleQuestions] = useState([]);
  const [question, setQuestion] = useState('');
  const [greetingMessage, setGreetingMessage] = useState('Hello');
  const [userInputPlaceHolder, setUserInputPlaceHolder] = useState('Chat with chatbot');
  const [chatbotAlias, setChatbotAlias] = useState('');
  const profileImageInputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [imageError, setImageError] = useState('');
  const [imageTimestamp, setImageTimestamp] = useState(Date.now());
  const [modalMessage, setModalMessage] = useState('');
  const [chatbotRAGStatus, setChatbotRAGStatus] = useState(false);

  const axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'svg'];
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (!validExtensions.includes(fileExtension)) {
      setImageError('Only image formats (JPG, JPEG, PNG, GIF) are allowed.');
      return;
    }

    const formData = new FormData();
    formData.append('chatbot_image', file);
    formData.append('chatbot_id', selectedChatbotID);

    try {
      const response = await axios.post('https://dryangai.com/api/uploadChatbotImage/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setImageTimestamp(response.data.unique_id);
      setImageError('');

    } catch (error) {
      console.error('Error uploading chatbot image:', error);
      setModalMessage('Error uploading chatbot image');
      setShowModal(true);
    }
  };

  const handleImageClick = () => {
    profileImageInputRef.current.click();
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const saveChatbotAlias = async () => {
    if (!selectedChatbotID) return;
    const data = {
      chatbot_id: selectedChatbotID,
      chatbot_alias: chatbotAlias,
    };
    try {
      const response = await fetch('https://dryangai.com/api/saveChatbotAlias/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        alert('Chatbot alias saved successfully');
      } else {
        alert('Failed to save chatbot alias');
      }
    } catch (error) {
      console.error('Error saving chatbot alias:', error);
      alert('An error occurred while saving the chatbot alias. Please try again.');
    }
  };

  const saveUserInputPlaceHolder = async () => {
    if (!selectedChatbotID) return;
    const data = {
      chatbot_id: selectedChatbotID,
      user_input_placeholder: userInputPlaceHolder,
    };
    try {
      const response = await fetch('https://dryangai.com/api/saveUserInputPlaceholder/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        alert('User input placeholder saved successfully');
        
      } else {
        alert('Failed to save user input placeholder');
      }
    } catch (error) {
      console.error('Error saving user input placeholder:', error);
      alert('An error occurred while saving the user input placeholder. Please try again.');
    }
  };

  const saveGreetingMessage = async () => {
    if (!selectedChatbotID) return;
    const data = {
      chatbot_id: selectedChatbotID,
      greeting: greetingMessage,
    };
    try {
      const response = await fetch('https://dryangai.com/api/saveChatbotGreeting/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        alert('Greeting message saved successfully');
      } else {
        alert('Failed to save greeting message');
      }
    } catch (error) {
      console.error('Error saving greeting message:', error);
      alert('An error occurred while saving the greeting message. Please try again.');
    }
  };
  
  
  const getChatbotInfo = async () => {
    if (!selectedChatbotID) return;
    const data = {
      chatbotID: selectedChatbotID,
    };
    try {
      const response = await fetch('https://dryangai.com/api/getChatbotInfo/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const responseData = await response.json();
        setUserInputPlaceHolder(responseData.chatbot['chatbot_userInputPlaceholder']);
        setGreetingMessage(responseData.chatbot['chatbot_greeting']);
        setChatbotAlias(responseData.chatbot['chatbot_alias']);
        setChatbotRAGStatus(responseData.chatbot['chatbot_RAG_status']);
      } else {
        console.error('Failed to fetch user input placeholder');
      }
    } catch (error) {
      console.error('Error fetching user input placeholder:', error);
    }
  };

  const handleChatbotChange = (event) => {
    const selectedChatbotName = event.target.value;
    setSelectedChatbotName(selectedChatbotName);
    const selectedChatbot = chatbots.find(chatbot => chatbot.chatbot_name === selectedChatbotName);
    setSelectedChatbotID(selectedChatbot ? selectedChatbot.chatbot_id : '');
  };

  const fetchAllowedDomains = async () => {
    if (!selectedChatbotID) return;
    const data = {
      chatbot_id: selectedChatbotID,
    };
    try {
      const response = await fetch('https://dryangai.com/api/checkDomains/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const responseData = await response.json();
        setAllowedDomains(responseData.domains);
      } else {
        console.error('Failed to fetch allowed domains');
      }
    } catch (error) {
      console.error('Error fetching allowed domains:', error);
    }
  };

  const fetchExampleQuestions = async () => {
    if (!selectedChatbotID) return;
    const data = {
      chatbot_id: selectedChatbotID,
    };
    try {
      const response = await fetch('https://dryangai.com/api/getExampleQuestions/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const responseData = await response.json();
        setExampleQuestions(responseData.exampleQuestions);
      } else {
        console.error('Failed to fetch example questions');
      }
    } catch (error) {
      console.error('Error fetching example questions:', error);
    }
  };

  useEffect(() => {
    fetchAllowedDomains();
    getChatbotInfo();
    fetchExampleQuestions();
  }, [selectedChatbotID]);

  const handleDomainChange = (event) => {
    setDomain(event.target.value);
  };

  const handleExampleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };


  const deleteDomain = async (domain_id) => {
    const data = {
      chatbot_id: selectedChatbotID,
      domain_id: domain_id,
    };
    try {
      const response = await fetch('https://dryangai.com/api/deleteDomain/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        fetchAllowedDomains();
      } else {
        alert('Failed to delete the domain. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting domain:', error);
      alert('An error occurred while deleting the domain. Please try again.');
    }
  }

  const deleteExampleQuestion = async (question_id) => {
    const data = {
      chatbot_id: selectedChatbotID,
      question_id: question_id,
    };
    try {
      const response = await fetch('https://dryangai.com/api/deleteExampleQuestion/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        fetchExampleQuestions();
      } else {
        alert('Failed to delete the question. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting question:', error);
      alert('An error occurred while deleting the question. Please try again.');
    }
  }

  const addDomain = async () => {
    if (!selectedChatbotName) {
      alert('Please choose a chatbot before submitting the domain');
      return;
    }
    if (!domain) {
      alert('Please enter a domain before submitting');
      return;
    }
  
    const data = {
      chatbot_name: selectedChatbotName,
      chatbot_id: selectedChatbotID,
      domain: domain,
    };

    try {
      const response = await fetch('https://dryangai.com/api/addDomain/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        fetchAllowedDomains();
        setDomain(''); // Clear the domain input field
      } else {
        alert('Failed to submit the domain. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting domain:', error);
      alert('An error occurred while submitting the domain. Please try again.');
    }
  };

  const addExampleQuestion = async () => {
    if (!selectedChatbotName) {
      alert('Please choose a chatbot before submitting the example question');
      return;
    }
    if (!question) {
      alert('Please enter a question before submitting');
      return;
    }
  
    const data = {
      chatbot_name: selectedChatbotName,
      chatbot_id: selectedChatbotID,
      question: question,
    };

    try {
      const response = await fetch('https://dryangai.com/api/addExampleQuestion/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        fetchExampleQuestions();
        setQuestion(''); // Clear the question input field
      } else {
        alert('Failed to submit the question. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting question:', error);
      alert('An error occurred while submitting the question. Please try again.');
    }
  };

  const handleChatbotRAGStatusToggle = async (selectedChatbotID, chatbotRAGStatus) => {
    const newStatus = !chatbotRAGStatus;

    try {
      await axiosInstance.post('https://dryangai.com/api/updateChatbotRAGStatus/', {
        chatbot_id: selectedChatbotID,
        chatbot_RAG_status: newStatus,
      });
      // setChatbotRAGStatus(newStatus);
      getChatbotInfo();
    } catch (error) {
      console.error('Error updating chatbot RAG status:', error);
    }
  };

  const ToggleSwitch = ({ isChecked, onChange }) => (
    <label className="switch">
      <input type="checkbox" checked={isChecked} onChange={onChange} />
      <span className={`slider ${isChecked ? 'green' : 'red'}`}></span>
    </label>
  );
  return (
    <div>
      <Helmet>
      <title>Configure my chatbots -- Dr. Yang AI</title>
      <meta name="description" content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot.." />
      <meta name="keywords" content="AI, Chatbot, Customized, Personal, Customer Service, Knowledge" />
      </Helmet>
      <h2>{t('chatbotConfiguration')}</h2>
      <h4>{t('step1')}: {t('chooseChatbot')}</h4>
      {t('chooseAChatbotToConfigure')}:
      <select name="selectedChatbotName" className="form-control" onChange={handleChatbotChange}>
        <option value="">{t('selectAChatbot')}</option>
        {chatbots.map(chatbot => (
          <option key={chatbot.chatbot_id} value={chatbot.chatbot_name}>
            {chatbot.chatbot_name}
          </option>
        ))}
      </select>
      {!selectedChatbotName && <span style={{ color: 'red' }}>{t('pleaseSelectAChatbot')}</span>}
      {selectedChatbotName && <span style={{ color: 'green' }}>{t('selected')} {selectedChatbotName}</span>}
      {selectedChatbotName && <div>
      <br />
      <h4>{t('step2')}: {t('configureChatbot')}</h4> 
        <p>
          <img 
            className="profile-image"
            src={`https://dryangai.com/api/media/chatbots/${selectedChatbotName}/Logo.png?t=${imageTimestamp}`} 
            alt="chatbot"
            onClick={handleImageClick}
            onError={(e) => e.target.src = 'https://dryangai.com/api/media/chatbots/defaultProfileImage.png'} 
            style={{ cursor: 'pointer' }}  // Add this line
          />
          <input
            type="file"
            ref={profileImageInputRef}
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />
        </p>
        {t('Chatbot Alias')}:
        <input type="text" 
          className="form-control" 
          value={chatbotAlias} 
          onChange={(event) => setChatbotAlias(event.target.value)}
        />
      <button onClick={saveChatbotAlias}>{t('save')}</button>
      <br />
      {t('allowedDomains')}:
      <br />
      ({t('add * to allow all domains')})
      <br />
      <span style={{ color: 'green' }}>dryangai.com ({t('default')})</span>
      <span style={{ color: 'green' }}>www.dryangai.com ({t('default')})</span>
      {allowedDomains.map(allowedDomain => (
        <div key={allowedDomain.domain_id}>
          <span style={{ color: 'green' }}>{allowedDomain.domain}</span>
          <button onClick={() => deleteDomain(allowedDomain.domain_id)}>{t('delete')}</button>
        </div>
      ))}
      <input
        type="text"
        className="form-control"
        onChange={handleDomainChange}
        value={domain}
      />
      <button onClick={addDomain}>{t('addDomain')}</button>
      <br />
      {t('greetingMessage')}:
      <textarea
        className="form-control"
        onChange={(event) => setGreetingMessage(event.target.value)}
        value={greetingMessage}
      />
      <button onClick={saveGreetingMessage}>{t('save')}</button>
      <br />
      {t('userInputPlaceHolderText')}:
      <textarea
        className="form-control"
        onChange={(event) => setUserInputPlaceHolder(event.target.value)}
        value={userInputPlaceHolder}
      />
      <button onClick={saveUserInputPlaceHolder}>{t('save')}</button>
      
      
      <br />
      {t('exampleQuestions')}:
      <br />
      {exampleQuestions.map(exampleQuestion => (
        <div key={exampleQuestion.question_id}>
          <span style={{ color: 'green' }}>{exampleQuestion.question}</span>
        <Button onClick={() => deleteExampleQuestion(exampleQuestion.question_id)}>{t('delete')}</Button>
        </div>
      ))}
      <input
        type="text"
        className="form-control"
        onChange={handleExampleQuestionChange}
        value={question}
      />
      <button onClick={addExampleQuestion}>{t('addExampleQuestions')}</button>
      <br />
      {t('customizedOrGeneral')}:
      <br />
      <ToggleSwitch
        isChecked={chatbotRAGStatus}
        onChange={() => handleChatbotRAGStatusToggle(selectedChatbotID, chatbotRAGStatus)}
      />
      <br />
      {chatbotRAGStatus && <span style={{ color: 'green' }}>{t('chatbotOnlyResponsesBasedOnTheCustomizedMemory')}</span>}
      {!chatbotRAGStatus && <span style={{ color: 'red' }}>{t('chatbot answers questions without the customized memory')}</span>}
      </div>
      }
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> 
    </div>
  );
}

export default ChatbotConfigPage;
