import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import styles from './AdminPage.module.css';

function Users() {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageInput, setPageInput] = useState('');
  const usersPerPage = 10;
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({
    password: '',
    email: '',
    phone: ''
  });

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const response = await axios.get('https://dryangai.com/api/showUsers/');
      setUsers(response.data.users);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const deleteUser = async (user_id, username) => {
    const confirmed = window.confirm('Are you sure you want to delete this user?');
    if (confirmed) {
      try {
        await axios.post('https://dryangai.com/api/deleteUser/', {
          user_id,
          username,
        });
        getUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setEditForm({
      password: user.password,
      email: user.email,
      phone: user.phone
    });
    setShowUserModal(true);
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditForm(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSaveClick = async () => {
    try {
      await axios.post('https://dryangai.com/api/updateUserProfile/', {
        user_id: selectedUser.id,
        ...editForm,
      });
      getUsers(); // Refresh the user list
      setShowUserModal(false);
      setSelectedUser(null);
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
  };

  const handleCloseUserModal = () => {
    setShowUserModal(false);
    setSelectedUser(null);
    setIsEditing(false);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const nextPageUsers = () => {
    if (currentPage < Math.ceil(users.length / usersPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageUsers = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const lastPageUsers = () => {
    setCurrentPage(Math.ceil(users.length / usersPerPage));
  };

  const firstPageUsers = () => {
    setCurrentPage(1);
  };

  const handlePageInputChange = (event) => {
    setPageInput(event.target.value);
  };

  const goToPageUsers = () => {
    const page = Number(pageInput);
    if (page > 0 && page <= Math.ceil(users.length / usersPerPage)) {
      setCurrentPage(page);
    }
    setPageInput('');
  };

  return (
    <div>
      {users.length > 0 ? (
        <>
          <div className={styles['user-list-content']}>
            <h2>User List</h2>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Username</th>
                  <th>Password</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Details</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.username}</td>
                    <td>{user.password}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>
                      <button className="btn btn-primary btn-sm" onClick={() => handleUserClick(user)}>Details</button>
                    </td>
                    <td>
                      <button className="btn btn-danger btn-sm" onClick={() => deleteUser(user.id, user.username)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.pagination}>
            <button onClick={firstPageUsers} disabled={currentPage === 1}>First</button>
            <button onClick={prevPageUsers} disabled={currentPage === 1}>Previous</button>
            <input
              type="text"
              value={pageInput}
              className={styles.pageInput}
              onChange={handlePageInputChange}
              placeholder={currentPage.toString()}
            />
            <button onClick={goToPageUsers}>Go</button>
            <button onClick={nextPageUsers} disabled={currentPage === Math.ceil(users.length / usersPerPage)}>Next</button>
            <button onClick={lastPageUsers} disabled={currentPage === Math.ceil(users.length / usersPerPage)}>Last</button>
          </div>
        </>
      ) : (
        <p>No users available</p>
      )}

      <Modal show={showUserModal} onHide={handleCloseUserModal}>
        <Modal.Header closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser && !isEditing ? (
            <div>
              <p><strong>ID:</strong> {selectedUser.id}</p>
              <p><strong>Username:</strong> {selectedUser.username}</p>
              <p><strong>Password:</strong> {selectedUser.password}</p>
              <p><strong>Email:</strong> {selectedUser.email}</p>
              <p><strong>Phone:</strong> {selectedUser.phone}</p>
            </div>
          ) : (
            <Form>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="text"
                  name="password"
                  value={editForm.password}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={editForm.email}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={editForm.phone}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isEditing ? (
            <Button variant="success" onClick={handleSaveClick}>
              Save
            </Button>
          ) : (
            <Button variant="primary" onClick={handleEditClick}>
              Edit
            </Button>
          )}
          <Button variant="secondary" onClick={handleCloseUserModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Users;
