// TrainChatbots.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

const TrainChatbots = ({ chatbots, user_id }) => {
  const { t } = useTranslation();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [trainingProgress, setTrainingProgress] = useState(0);
  const [reloadProgress, setReloadProgress] = useState(0);
  const [clearMemoryProgress, setClearMemoryProgress] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [fileError, setFileError] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [selectedChatbotName, setSelectedChatbotName] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const fileInputRef = useRef(null);

  const axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const clearChromaDatabase = async () => {
    try {
      setClearMemoryProgress(0);
      setIsButtonDisabled(true);
      const response = await axiosInstance.post('https://dryangai.com/api/clearChromaDatabase/', {
        chatbotName: selectedChatbotName,
      }, {
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            setClearMemoryProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        }
      });
      setClearMemoryProgress(100);
      setIsButtonDisabled(false);
      if (response.data.result === 'success') {
        setModalMessage('Chroma database cleared successfully');
        fetchFileList(selectedChatbotName);
      } else {
        setModalMessage('Error clearing Chroma database');
      }
      setShowModal(true);
    } catch (error) {
      console.error('Error clearing Chroma database:', error);
      setModalMessage('Error clearing Chroma database');
      setShowModal(true);
      setIsButtonDisabled(false);
    }
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    const files = fileInputRef.current.files;

    const validExtensions = ['pdf', 'txt'];
    const invalidFiles = Array.from(files).filter(file => !validExtensions.includes(file.name.split('.').pop().toLowerCase()));

    if (invalidFiles.length > 0) {
      setFileError('Only PDF and TXT files are allowed.');
      return;
    }

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    formData.append('user_id', user_id);
    formData.append('selectedChatbotName', selectedChatbotName);

    try {
      setIsButtonDisabled(true);
      const response = await axios.post('https://dryangai.com/api/uploadFiles/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        }
      });
      setUploadProgress(100);
      setModalMessage('Files uploaded successfully');
      setShowModal(true);
      fetchFileList(selectedChatbotName);
      setFileError('');
      setIsButtonDisabled(false);
    } catch (error) {
      console.error('Error uploading files:', error);
      setModalMessage('Error uploading files');
      setShowModal(true);
      setIsButtonDisabled(false);
    }
  };

  const fetchFileList = async (chatbotName) => {
    try {
      const response = await axios.get('https://dryangai.com/api/listTrainingFiles/', {
        params: { chatbot_name: chatbotName },
      });
      setFileList(response.data.files);
    } catch (error) {
      console.error('Error fetching file list:', error);
      setError('Error fetching file list');
    }
  };

  const handleFileDelete = async (fileName, event) => {
    event.stopPropagation();
    try {
      const response = await axiosInstance.post('https://dryangai.com/api/deleteFile/', {
        chatbot_name: selectedChatbotName,
        file_name: fileName,
      });
      setModalMessage('File deleted successfully');
      setShowModal(true);
      fetchFileList(selectedChatbotName);
    } catch (error) {
      console.error('Error deleting file:', error);
      setModalMessage('Error deleting file');
      setShowModal(true);
    }
  };

  const trainModel = async () => {
    try {
      setTrainingProgress(0);
      setIsButtonDisabled(true);
      const response = await axios.post('https://dryangai.com/api/trainModel/', {
        user_id,
        selectedChatbotName,
      }, {
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            setTrainingProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        }
      });
      setTrainingProgress(100);
      setModalMessage('Chatbot has been customized successfully');
      setShowModal(true);
      setIsButtonDisabled(false);
    } catch (error) {
      console.error('Error customizing model:', error);
      setModalMessage('Error customizing model');
      setShowModal(true);
      setIsButtonDisabled(false);
    }
  };

  const reloadApache = async () => {
    try {
      setReloadProgress(0);
      setIsButtonDisabled(true);
      const response = await axiosInstance.post('https://dryangai.com/api/reloadApache/', {}, {
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            setReloadProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        }
      });
      setReloadProgress(100);
      setModalMessage(response.data.status);
      setShowModal(true);
      setIsButtonDisabled(false);
    } catch (error) {
      console.error('Error reloading Apache:', error);
      setModalMessage('Failed to reload Apache');
      setShowModal(true);
      setIsButtonDisabled(false);
    }
  };

  const handleChatbotChange = (event) => {
    setSelectedChatbotName(event.target.value);
    fetchFileList(event.target.value);
  };

  return (
    <div className="train-chatbots-content">
        <Helmet>
        <title>Customize chatbots -- Dr. Yang AI</title>
        <meta name="description" content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot.." />
        <meta name="keywords" content="AI, Chatbot, Customized, Personal, Customer Service, Knowledge" />
        </Helmet>


        <h2>{t('Customize Chatbot')}</h2>
      
        <h4>{t('step1')}: {t('chooseChatbot')}</h4>
          {t('Select a chatbot to customize')}: 
          
          <select name="selectedChatbotName" className="form-control" onChange={handleChatbotChange}>
          <option value="">{t('selectAChatbot')}</option>
          {chatbots.map(chatbot => (
            <option key={chatbot.chatbot_id} value={chatbot.chatbot_name}>
              {chatbot.chatbot_name}
            </option>
          ))}
        </select>
      
        {!selectedChatbotName && <span style={{ color: 'red' }}>{t('pleaseSelectAChatbot')}</span>}
        {selectedChatbotName && <span style={{ color: 'green' }}>{t('selected')}{selectedChatbotName}</span>}
        <br />
        {selectedChatbotName && 
        <div>
          <h4>{t('step2')}: {t('Initialize Chatbot (optional)')}</h4>
           {t('Clear existing knowledge in the chatbot')} <button type="button" className="btn btn-warning btn-sm" onClick={clearChromaDatabase} disabled={isButtonDisabled}> {t('Clear Chatbot Memory')}</button>
          <br />
           {t('If you clear the memory, you will need to re-customize the chatbot.')}<br />
            {t('If you do not clear the memory, the chatbot will be customized with the existing knowledge.')}
          {clearMemoryProgress > 0 && (
            <div className="progress-bar">
              <div className="progress" style={{ width: `${clearMemoryProgress}%` }}>
                {clearMemoryProgress}%
              </div>
            </div>
          )}
        
          <p>
          
          <br />
          <h4>{t('step3')}: {t('Choose Files')}:</h4>
          {fileList.length > 0 && (
            <div className="file-list">
              <p>{t('Existing files to be used for customizing chatbot')}:</p>
              <ul>
                {fileList.map((file, index) => (
                  <li key={index}>
                    {file}
                    <button type="button" className="btn btn-danger btn-sm" onClick={(event) => handleFileDelete(file, event)}>{t('delete')}</button>
                  </li>
                ))}
              </ul>
            </div>
            )}
            {t('Add extra files (PDF or TXT) to customize the chatbot')}:
          <form onSubmit={handleFileUpload}>
            <input type="file" multiple name="files" ref={fileInputRef} className="form-control" />
            {fileError && <p className="error-message">{fileError}</p>}
            <button type="submit" className="btn btn-primary" disabled={isButtonDisabled}>{t('Upload Files')}</button>
          </form>
          </p>
          
        
        
        {uploadProgress > 0 && (
          <div className="progress-bar">
            <div className="progress" style={{ width: `${uploadProgress}%` }}>
              {uploadProgress}%
            </div>
          </div>
        )}
        <br />
        <h4>{t('step4')}: {t('Customize Chatbot')}</h4>
        <button className="btn btn-primary btn-sm" onClick={trainModel} disabled={isButtonDisabled}>{t('Customize Chatbot')}</button>
        {trainingProgress > 0 && (
          <div className="progress-bar">
            <div className="progress" style={{ width: `${trainingProgress}%` }}>
              {trainingProgress}%
            </div>
          </div>
        )}
        <br /><br />
        <h4>{t('step5')}: {t('Refresh System')}:</h4>
        <button className="btn btn-primary btn-sm" onClick={reloadApache} disabled={isButtonDisabled}>{t('Refresh System')}</button>
        {reloadProgress > 0 && (
          <div className="progress-bar">
            <div className="progress" style={{ width: `${reloadProgress}%` }}>
              {reloadProgress}%
            </div>
          </div>
        )}

      </div>}
    </div>
  );
};

export default TrainChatbots;
