import React from 'react';
import { FaUsers, FaRobot, FaUserCog } from 'react-icons/fa';
import './Sidebar.css'; // Ensure this file exists for styling

function Sidebar({ onMenuClick, toggleSidebar, isSidebarOpen }) {
  return (
    <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
      <div className="sidebarContent">
        <br /><br /><br /><br /><br /><br /><br /><br />
        <button className="menuButton" onClick={() => onMenuClick('adminProfile')}>
          <FaUserCog className="icon" />
          {isSidebarOpen && <span>Admin Profile</span>}
        </button>
        <button className="menuButton" onClick={() => onMenuClick('users')}>
          <FaUsers className="icon" />
          {isSidebarOpen && <span>Users</span>}
        </button>
        <button className="menuButton" onClick={() => onMenuClick('chatbots')}>
          <FaRobot className="icon" />
          {isSidebarOpen && <span>Chatbots</span>}
        </button>
        
        {/* Add more buttons as needed */}
      </div>
      <button className="toggleButton" onClick={toggleSidebar}>
        {isSidebarOpen ? '<<' : '>>'}
      </button>
    </div>
  );
}

export default Sidebar;
