import React from 'react';
import styles from './Sidebar.module.css'; // Import the CSS module
import { FaUser, FaRobot, FaPlus, FaCog, FaWrench, FaRocket } from 'react-icons/fa'; // Import icons from react-icons
import { useTranslation } from 'react-i18next';

function Sidebar({ onMenuClick, toggleSidebar, isSidebarOpen }) {
  const { t } = useTranslation();

  return (
    <div className={`${styles.sidebar} ${isSidebarOpen ? styles.open : styles.closed}`}>
      <div className={styles.sidebarContent}>
        <br /><br /><br /><br /><br /><br /><br /><br />
        <button className={styles.menuButton} onClick={() => onMenuClick('profile')}>
          <FaUser className={styles.icon} />
          {isSidebarOpen && <span>{t('Profile')}</span>}
        </button>
        <button className={styles.menuButton} onClick={() => onMenuClick('chatbots')}>
          <FaRobot className={styles.icon} />
          {isSidebarOpen && <span>{t('My Chatbots')}</span>}
        </button>
        <button className={styles.menuButton} onClick={() => onMenuClick('createChatbot')}>
          <FaPlus className={styles.icon} />
          {isSidebarOpen && <span>{t('Create Chatbot')}</span>}
        </button>
        <button className={styles.menuButton} onClick={() => onMenuClick('trainChatbots')}>
          <FaCog className={styles.icon} />
          {isSidebarOpen && <span>{t('Custom Chatbot')}</span>}
        </button>
        <button className={styles.menuButton} onClick={() => onMenuClick('configureChatbots')}>
          <FaWrench className={styles.icon} />
          {isSidebarOpen && <span> {t('Config Chatbot')}</span>}
        </button>
        <button className={styles.menuButton} onClick={() => onMenuClick('deployChatbots')}>
          <FaRocket className={styles.icon} />
          {isSidebarOpen && <span> {t('Deploy Chatbot')}</span>}
        </button>
        {/* Add more buttons as needed */}
      </div>
      <button className={styles.toggleButton} onClick={toggleSidebar}>
        {isSidebarOpen ? '<<' : '>>'}
      </button>
    </div>
  );
}

export default Sidebar;
