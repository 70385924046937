import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './WeatherPage.module.css'; // Import the CSS module

function WeatherForm() {
    const [responseMessage, setResponseMessage] = useState(''); // State to store the response from the backend
    const [errorMessage, setErrorMessage] = useState(''); // State to store any error messages
    const [ipAddress, setIpAddress] = useState(''); // State to store the IP address
    const [physicalAddress, setPhysicalAddress] = useState(''); // State to store the physical address
    const [weatherData, setWeatherData] = useState(''); // State to store the weather data
    const [tone, setTone] = useState('normal'); // State to store the selected tone

    // Fetch the user's IP address and physical address when the component mounts
    useEffect(() => {
        const fetchIpAndLocation = async () => {
            try {
                // Get the user's IP address
                const ipResponse = await axios.get('https://api.ipify.org?format=json');
                const ip = ipResponse.data.ip;
                setIpAddress(ip); // Set the IP address in state

                // Send the IP address to the backend to get the physical address
                const locationResponse = await axios.post('https://dryangai.com/api/whatToWearBasedOnWeather/', { ipAddress: ip }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                
                const { city, region, country } = locationResponse.data.physicalAddress;
                const address = `${city}, ${region}, ${country}`;
                setPhysicalAddress(address); // Set the physical address in state

                // Automatically trigger sending weather data after fetching the location
                sendWeatherData(locationResponse.data.weather, tone);

            } catch (error) {
                console.error('Error fetching IP or location data:', error);
                setErrorMessage('Failed to fetch IP or location data');
            }
        };

        fetchIpAndLocation();
    }, []);

    // UseEffect to trigger sendWeatherData whenever the tone changes
    useEffect(() => {
        if (weatherData) {
            sendWeatherData(weatherData, tone);
        }
    }, [tone]); // Dependency array includes tone to trigger when tone changes

    // Define a function to send weather information to the backend
    async function sendWeatherData(weatherData, selectedTone) {
        try {
            const response = await axios.post('https://dryangai.com/api/whatToWearBasedOnWeather/', { weatherData, ipAddress, tone: selectedTone }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                // Handle successful response from the backend
                setWeatherData(response.data.weather); // Store the weather data returned by the backend
                formatResponseMessage(response.data.response); // Format and set the response message
                setErrorMessage('');
            } else {
                // Handle error response from the backend
                setErrorMessage('Failed to send weather data: ' + response.status);
                setResponseMessage('');
            }
        } catch (error) {
            // Handle any network or other errors
            setErrorMessage('An error occurred while sending weather data: ' + error.message);
            setResponseMessage('');
        }
    }

    // Format the response message for better display
    const formatResponseMessage = (message) => {
        const formattedMessage = message.split('.').filter(line => line.trim() !== '').map((line, index) => (
            <p key={index}>{line.trim()}</p>
        ));
        setResponseMessage(formattedMessage);
    };

    return (
        <div className={styles.app}>
            <br /><br />
            <div className={styles.title}>
                <h1>Clothing Suggestions Based on Weather</h1>
            </div>
            <div className={styles.IP}>
                <p>Your IP Address: {ipAddress}</p> {/* Display the IP address */}
            </div>
            <div className={styles.location}>
                <p>Your Location: {physicalAddress}</p> {/* Display the physical address */}
            </div>
            <div className={styles.tone}>
                <label htmlFor="tone">Select Tone:</label>
                <select 
                    id="tone" 
                    value={tone} 
                    onChange={(e) => setTone(e.target.value)}
                >
                    <option value="normal">Normal</option>
                    <option value="sassy">Sassy</option>
                    <option value="polite">Polite</option>
                    <option value="crazy">Crazy</option>
                    <option value="shameless">Shameless</option>
                </select>
            </div>
            <br />
            {weatherData && (
                <div className={styles.weather}>
                    <h3>Weather Information:</h3>
                    <p>{weatherData}</p> {/* Display the weather information */}
                </div>
            )}
            {responseMessage && (
                <div className={styles.suggestion}>
                    <h3>Suggested Clothing:</h3>
                    <ul>{responseMessage}</ul> {/* Display the formatted clothing suggestions */}
                </div>
            )}
            {errorMessage && (
                <div style={{ marginTop: '20px', color: 'red' }}>
                    <h3>Error:</h3>
                    <p>{errorMessage}</p>
                </div>
            )}
        </div>
    );
}

export default WeatherForm;
