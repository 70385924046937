import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios'; // Make sure to install axios with `npm install axios`
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import './Registration.css'; // This should be at the top of your App.js file
import { Helmet } from "react-helmet";

// Function to check if a string contains only English letters or numbers
function isEnglishLettersOrNumbers(str) {
  return /^[A-Za-z0-9]+$/.test(str);
}

// Function to mask the middle part of the password
function maskPassword(password) {
  const length = password.length;
  if (length <= 4) return password; // No need to mask short passwords
  const visiblePartLength = Math.ceil(length / 4);
  const visibleStart = password.slice(0, visiblePartLength);
  const visibleEnd = password.slice(-visiblePartLength);
  return `${visibleStart}***${visibleEnd}`;
}

// Registration function
async function registerUser(username, password, email, phone, setResponseMessage, setShowModal, t) {
  try {
    // Perform registration logic here
    const response = await axios.post('https://dryangai.com/api/registration/', {
      username,
      password,
      phone,
      email,
    });

    // Handle success response
    const handleSuccess = (response) => {
      const user_id = response.data.user_id;
      const username = response.data.username;
      const email = response.data.email;
      const password = response.data.password;
      // Update the response message with the account information
      const maskedPassword = maskPassword(password);
      setResponseMessage(`${t('congrats')}! \n${t('accountCreated')}\n
      ${t('accountInfo')}:\n
      - ${t('username')}: ${username} \n
      - ${t('email')}: ${email} \n
      - ${t('password')}: ${maskedPassword}\n
      ${t('canLogin')}`);
      setShowModal(true);
    };
    handleSuccess(response);
    console.log(`Registered user: ${response.data.username}`);
  } catch (error) {
    // Handle error response
    console.error('Error registering user:', error);
    if (error.response && error.response.data && error.response.data.error) {
      setResponseMessage(`Error: ${error.response.data.error}`);
    } else {
      setResponseMessage('Error registering user. Please try again later.');
    }
    setShowModal(true);
  }
}

// Registration form component
function RegistrationPage() {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isEnglishLettersOrNumbers(username)) {
      setResponseMessage(t('errorUsername'));
      setShowModal(true);
      return;
    }
    registerUser(username, password, email, phone, setResponseMessage, setShowModal, t);
  };

  const handleClose = () => setShowModal(false);

  return (
    <div className='registration'>
      <Helmet>
      <title>Sign up -- Dr. Yang AI</title>
      <meta name="description" content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot.." />
      <meta name="keywords" content="AI, Chatbot, Customized, Personal, Customer Service, Knowledge" />
      </Helmet>
      <form onSubmit={handleSubmit} className='registrationForm'>
        <label>
          {t('username')}:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </label>
        <label>
          {t('password')}:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </label>
        <label>
          {t('email')}:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>
        <label>
          {t('phone')}:
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </label>
        <button type="submit" className='registerSubmitButton'>{t('register')}</button>
        <br />
        <p>{t('hasAccount')} <a href="/login">{t('login')}</a></p>
      </form>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('registrationResponse')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{responseMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button variant="primary" href="/login">
            {t('login')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RegistrationPage;
