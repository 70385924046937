import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Row, Col } from 'react-bootstrap';
import styles from './AdminPage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';

function AdminProfile() {
  const [admin, setAdmin] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedAdmin, setEditedAdmin] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    const adminToken = localStorage.getItem('adminToken');
    if (adminToken) {
      getAdminProfile(adminToken);
    }
  }, []);

  const getAdminProfile = async (token) => {
    try {
      const response = await axios.post('https://dryangai.com/api/getAdminProfile/', {
        token,
      });
      setAdmin(response.data.admin);
      setEditedAdmin(response.data.admin);
    } catch (error) {
      console.error('Error fetching admin profile:', error);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedAdmin(admin);
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const dataToSend = {
        ...editedAdmin,
        token, // Include the token in the request body
      };
      await axios.post('https://dryangai.com/api/updateAdminProfile/', dataToSend);
      setAdmin(editedAdmin);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating admin profile:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedAdmin({ ...editedAdmin, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Function to mask the middle part of the password
  function maskPassword(password) {
    const length = password.length;
    if (length <= 4) return password; // No need to mask short passwords
    const visiblePartLength = Math.ceil(length / 4);
    const visibleStart = password.slice(0, visiblePartLength);
    const visibleEnd = password.slice(-visiblePartLength);
    return `${visibleStart}***${visibleEnd}`;
  }

  if (!admin) {
    return <p>Loading admin profile...</p>;
  }

  return (
    <div className={styles['admin-profile-content']}>
      <h2>Admin Profile</h2>
      <Form>
        <Form.Group as={Row}>
          <Form.Label column sm="2">ID:</Form.Label>
          <Col sm="10">
            <p className="form-control-plaintext">{admin.id}</p>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2">Username:</Form.Label>
          <Col sm="10">
            <p className="form-control-plaintext">{admin.username}</p>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2">Password:</Form.Label>
          <Col sm="10">
            {isEditing ? (
              <div className={styles['password-container']}>
                <Form.Control
                  type={passwordVisible ? 'text' : 'password'}
                  name="password"
                  value={editedAdmin.password}
                  onChange={handleChange}
                  className={styles['password-input']}
                />
                <FontAwesomeIcon
                  icon={passwordVisible ? faEyeSlash : faEye}
                  className={styles['password-icon']}
                  onClick={togglePasswordVisibility}
                />
              </div>
            ) : (
              <p className="form-control-plaintext">{maskPassword(admin.password)}</p>
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2">Email:</Form.Label>
          <Col sm="10">
            {isEditing ? (
              <Form.Control
                type="email"
                name="email"
                value={editedAdmin.email}
                onChange={handleChange}
              />
            ) : (
              <p className="form-control-plaintext">{admin.email}</p>
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2">Created At:</Form.Label>
          <Col sm="10">
            <p className="form-control-plaintext">{moment(admin.created_at).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')}</p>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2">Last Login:</Form.Label>
          <Col sm="10">
            <p className="form-control-plaintext">{moment(admin.last_login).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')}</p>
          </Col>
        </Form.Group>
        {isEditing ? (
          <div>
            <Button variant="primary" onClick={handleSave}>Save</Button>
            <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
          </div>
        ) : (
          <Button variant="primary" onClick={handleEdit}>Edit</Button>
        )}
      </Form>
    </div>
  );
}

export default AdminProfile;
