import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './LoginPage.css';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from "react-helmet";

function LoginPage() {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://dryangai.com/api/checkLogin/', { username, password });
      console.log('Response data:', response.data);

      if (response.status === 200) {
        localStorage.setItem('user', JSON.stringify(response.data));
        window.location.href = '/home';
      }
    } catch (error) {
      console.error('Error logging in:', error);
      const errorMessage = error.response?.data?.error || 'Login failed. Please try again.';
      setErrorMessage(errorMessage);
      setShowModal(true);
    }
  };

  const handleClose = () => setShowModal(false);

  return (
    <div className='login'>
      <Helmet>
      <title>Login to customize your own chatbot -- Dr. Yang AI</title>
      <meta name="description" content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot.." />
      <meta name="keywords" content="AI, Chatbot, Customized, Personal, Customer Service, Knowledge" />
      </Helmet>
      <form onSubmit={handleSubmit} className='loginForm'>
        <label>
          {t('username')}:
          <input type="text" value={username} onChange={handleChange(setUsername)} required />
        </label>
        <label>
          {t('password')}:
          <input type="password" value={password} onChange={handleChange(setPassword)} required />
        </label>
        <button type="submit" className='loginButton'>{t('login')}</button>
        <p>{t('noAccount')} <a href="/register">{t('register')}</a></p>
      </form>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login Failed</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LoginPage;
