import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './Sidebar'; // Ensure this file exists
import styles from './AdminPage.module.css'; // Import the CSS module
import Chatbots from './Chatbots';
import Users from './Users';
import AdminProfile from './AdminProfile';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from "react-helmet";

function AdminPage() {
  const [activePage, setActivePage] = useState('adminProfile'); // Track the active page
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Track the sidebar visibility
  const [showProfileMenu, setShowProfileMenu] = useState(false); // Track the profile menu visibility
  const navigate = useNavigate();
  const profileMenuRef = useRef(null); // Reference for the profile menu
  const [imageTimestamp, setImageTimestamp] = useState(Date.now());

  useEffect(() => {
    const adminToken = localStorage.getItem('adminToken');
    if (!adminToken) {
      navigate('/admin-login');
    } else {
      // Verify the token with the backend
      verifyAdminToken(adminToken);
    }
  }, [navigate]);

  const verifyAdminToken = async (token) => {
    try {
      const response = await axios.post('/api/verifyAdminToken/', {
        token,
      });
      if (!response.data.success) {
        localStorage.removeItem('adminToken');
        navigate('/admin-login');
      }
    } catch (error) {
      console.error('Error verifying admin token:', error);
      localStorage.removeItem('adminToken');
      navigate('/admin-login');
    }
  };

  const handleMenuClick = (page) => {
    setActivePage(page);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin-login');
  };

  const handleClickOutside = (event) => {
    if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
      setShowProfileMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.container}>
      <Helmet>
      <title>Customize your own chatbot -- Dr. Yang AI</title>
      <meta name="description" content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot.." />
      <meta name="keywords" content="AI, Chatbot, Customized, Personal, Customer Service, Knowledge" />
      </Helmet>
        <div className={styles['welcomeUser']}>
          <p style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <img
              src="/api/media/admins/defaultProfileImage.png" // Replace with the correct path to the profile photo
              alt="Profile"
              className={styles['profile-image-header']}
              onClick={toggleProfileMenu}
              onError={(e) => e.target.src = '/api/media/admins/defaultProfileImage.png'}
            />
          </p>
          {showProfileMenu && (
            <div className='menu' ref={profileMenuRef}>
              <div className='menu-profile'>
                <img
                  className="profile-image-header"
                  src={`/api/media/admins/defaultProfileImage.png?t=${imageTimestamp}`}
                  alt="Profile"
                  onError={(e) => e.target.src = '/api/media/admins/defaultProfileImage.png'}
                />
                <div className='menu-username'>
                  <span style={{ display: 'block' }}>
                    adminusername&nbsp;&nbsp;
                  </span>
                  <span style={{ display: 'block' }}>
                    adminemail&nbsp;&nbsp;
                  </span>
                </div>
              </div>
              <div className='menu-divider'><a href='#' onClick={() => handleMenuClick('adminProfile')}>&nbsp;&nbsp;Profile</a></div>
              <div className='menu-divider'><a href='#' onClick={() => handleMenuClick('chatbots')}>&nbsp;&nbsp;Chatbots</a></div>
              <div className='menu-divider'><a href='#' onClick={() => handleMenuClick('users')}>&nbsp;&nbsp;Users</a></div>
              <div className='menu-divider'><a href='#' onClick={handleLogout}>&nbsp;&nbsp;Logout</a></div>
            </div>
          )}
          </div>
        <Sidebar
        onMenuClick={handleMenuClick}
        toggleSidebar={toggleSidebar}
        isSidebarOpen={isSidebarOpen}
        />
      <div className={`${styles['main-content']} ${isSidebarOpen ? styles['sidebar-open'] : styles['sidebar-closed']}`}>
        {activePage === 'users' && <Users />}
        {activePage === 'chatbots' && <Chatbots />}
        {activePage === 'adminProfile' && <AdminProfile />}
      </div>
    </div>
  );
}

export default AdminPage;
