import React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

const CreateChatbot = ({ setActivePage, user_id, axiosInstance, setModalMessage, setShowModal }) => {
  const { t } = useTranslation();

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const chatbotName = formData.get('chatbotName');
    const chatbotDescription = formData.get('chatbotDescription');
    const chatbotModel = formData.get('chatbotModel');
    const chatbotAPIKey = formData.get('chatbotAPIKey');
    const chatbotStatus = formData.get('chatbotStatus');

    // Validation for allowed characters in chatbot name
    const validNamePattern = /^[a-zA-Z0-9_-]+$/;
    if (!validNamePattern.test(chatbotName)) {
      setModalMessage(t('Chatbot name can only contain letters, numbers, underscores, and dashes.'));
      setShowModal(true);
      return;
    }

    axiosInstance.post('https://dryangai.com/api/copy_folder/', {
      chatbotName,
      chatbotDescription,
      user_id,
      chatbotModel,
      chatbotAPIKey,
      chatbotStatus,
    })
      .then(response => {
        setActivePage('chatbots');
      })
      .catch(error => {
        console.error('Error submitting form:', error);
        if (error.response && error.response.data && error.response.data.error === 'Chatbot name already exists, please choose another name for your chatbot') {
          setModalMessage('Chatbot name already exists, please choose another name for your chatbot');
          setShowModal(true);
        } else {
          setModalMessage('An error occurred while submitting the form. Please try again.');
          setShowModal(true);
        }
      });
  };

  return (
    <div className="chatbot-content">
      <Helmet>
      <title>Create a customized chatbot -- Dr. Yang AI</title>
      <meta name="description" content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot.." />
      <meta name="keywords" content="AI, Chatbot, Customized, Personal, Customer Service, Knowledge" />
      </Helmet>
      <h2>{t('createChatbot')}</h2>
      <form onSubmit={handleFormSubmit}>
        <p>{t('name')}: <input type="text" name="chatbotName" placeholder={t('enterChatbotName')} className="form-control" /></p>
        <p>{t('description')}: <input type="text" name="chatbotDescription" placeholder={t('describeChatbot')} className="form-control" /></p>
        <p>{t('model')}: 
          <select name="chatbotModel" className="form-control">
            <option value="gpt-4o-mini">gpt-4o-mini</option>
          </select>
        </p>
        <input type="hidden" name="chatbotAPIKey" defaultValue="sk-proj-Dg4KIqseVDykTc1A0wwQT3BlbkFJwOjMxp7YVy6psi22qfBh" />
        <p>{t('status')}: 
        <select name="chatbotStatus" className="form-control">
          <option value="True">{t('active')}</option>
          <option value="False">{t('inactive')}</option>
        </select>
        </p>
        <p><button type="submit" className="btn btn-primary">{t('createChatbot')}</button></p>
      </form>
    </div>
  );
};

export default CreateChatbot;
