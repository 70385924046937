import axios from 'axios';

const getUserCountry = async () => {
  try {
    const response = await axios.get('https://ipinfo.io/json?token=27d9d3c5e12f68');
    return response.data.country;
  } catch (error) {
    console.error('Error fetching IP info:', error);
    return 'US'; // Default to US if there's an error
  }
};

export default getUserCountry;
